import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillFrown } from "react-icons/ai";
import { BASE_URI } from "../BASE_URI";
import { useAuth } from "../hooks/useAuth.tsx";
import { message } from "antd";
import { WarningOutlined, StarFilled, StarTwoTone } from "@ant-design/icons";

export default function ReceiveStock({ products,categories }) {
  const [active, setActive] = useState(2);
  const [searchText, setSearchText] = useState("");

  const category = categories;
  const searchFilterFunction = (text) => {
    setSearchText(text);
  };

  // function getDisplayedData() {
  //   if (searchText === "") {
  //     return products || [];
  //   } else {
  //     return (
  //       products.filter((product) =>
  //         product.product_name.toLowerCase().includes(searchText.toLowerCase())
  //       ) || []
  //     );
  //   }
  // }

  function getDisplayedData() {
    if (searchText === "") {
      if (active != 0) {
        return (
          products.filter((product) => product.category_id == active) || []
        );
      } else {
        return products || [];
      }
    } else {
      return (
        products.filter((product) =>
          product.product_name.toLowerCase().includes(searchText.toLowerCase())
        ) || []
      );
    }
  }


  const menu = getDisplayedData();

  const [productsList, setProductList] = useState([]);
  const [itemsSold, setItemsSold] = useState(0);

  const [supplierName, setSupplierName] = useState("");
  const [supplierContact, setSupplierContact] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const Toast = (message) => {
    messageApi.error(message);
  };

  const SuccessToast = (message) => {
    messageApi.success(message);
  };

  let { loginDetails, getEmployee } = useAuth();
  // const menu = [];
  const receiveStockHandler = () => {
    if (productsList.length == 0) {
      Toast("Kindly add quantity for atleast one inventory Item to proceed!");
      return;
    }
    if (supplierName == "" || supplierContact == "") {
      Toast(
        "Lisa needs you to provide both the supplier's name and the contact details!"
      );

      return;
    }

    setLoading(true);
    axios
      .post(
        `${BASE_URI}/receive-stock/${loginDetails.BUSINESS_ID}`,
        {
          sm_details: JSON.stringify(productsList),
          employee_id: getEmployee,
          supplier_id: supplierName,
          user_id: getEmployee,
          type: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + loginDetails.TOKEN,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        // setstockSuccess(true);
        SuccessToast("Stock received successfully!");
        // console.log(res.data);
        setSupplierContact("");
        setSupplierName("");
      })
      .catch((err) => {
        console.log(err);
        Toast("OOPs we encountered an error while completing the request!");

        setLoading(false);
        // setstockSuccess(true);
      });
  };

  return (
    <div className="px-[2rem]">
      {contextHolder}
      <p className="text-[1.5rem] my-[1rem]">Receive Stock</p>
      <p className="text-[1rem] my-[1rem]">Categories</p>
      <div className="flex gap-x-[1rem] ">
        {category.map((c) => {
          return (
            <div
              onClick={() => {
                setActive(c.id);
              }}
              className={`${
                active == c.id ? "bg-[#2774E9] text-white" : "bg-[#EAEAEA]"
              } px-[1.6rem] py-[0.6rem] rounded-[1.3rem] cursor-pointer`}
            >
              {c.category_name}
            </div>
          );
        })}
        {category.length == 0 && (
          <div className="text-orange-500 flex justify-center gap-3 items-center">
            <WarningOutlined />
            <p>
              Your Inventory Has no Active category. Create a few using the Lisa
              mobile App
            </p>
          </div>
        )}
      </div>
      <div className="flex gap-x-3 mt-[1rem]">
        <div className="w-[75%]">
          <div>
            <input
              value={searchText}
              onChange={(e) => searchFilterFunction(e.target.value)}
              placeholder="Search"
              className="outline-none w-full border-2 rounded-md bg-white px-3 py-2"
            />
            <div className="grid grid-cols-2 mt-[1rem] max-h-[60vh]  overflow-x-hidden overflow-y-scroll">
              {menu.map((item) => {
                return (
                  <ReceiveStockInputComponent
                    item={item}
                    setProductList={setProductList}
                    productsList={productsList}
                    setItemsSold={setItemsSold}
                  />
                );
              })}
              {menu.length == 0 && (
                <div className="h-[20rem] col-span-2 justify-center flex-col flex items-center">
                  <p className="text-[1.3rem]">You have no Products!</p>
                  <AiFillFrown className="text-[4rem] text-[#2774E9]" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-[25%]">
          <div className="flex w-full justify-between">
            <p className="text-[1.3rem] text-[#2774e9]">Summary</p>
            {/* <p className="text-[1.3rem]">KES 5,678</p> */}
          </div>
          <p className="text-[1.2rem] my-[1rem]">
            {productsList.length} Products (0 Items Sold)
          </p>
          <div className=" border flex flex-col justify-center  px-4 py-3">
            <p className="my-3">Receive from</p>
            <input
              value={supplierName}
              onChange={(e) => setSupplierName(e.target.value)}
              className="border outline-[#2774e9] px-2 py-2 mb-4"
            />
            <p className="my-3">Contact (optional)</p>
            <input
              value={supplierContact}
              onChange={(e) => setSupplierContact(e.target.value)}
              className="border outline-[#2774e9] px-2 py-2 mb-4"
            />
          </div>
          {loading ? (
            <div className="bg-[#2774e9]/60 py-2 px-4 my-[3rem] flex justify-center text-white shadow-lg border-none rounded-[3rem] cursor-pointer">
              Saving...
            </div>
          ) : (
            <div
              onClick={receiveStockHandler}
              className="bg-[#2774e9] py-2 px-4 my-[3rem] flex justify-center text-white shadow-lg border-none rounded-[3rem] cursor-pointer"
            >
              Complete and save
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ReceiveStockInputComponent({
  item,
  setProductList,
  productsList,
  setItemsSold,
}) {
  const [quantityEntered, setquantityEntered] = useState();
  return (
    <div className="bg-[#fff] hover:shadow-lg hover:scale-101 py-4 px-4 transition-all duration-500  mx-3 my-2 border rounded-lg  ">
      <p>
        {item.product_name} @ Kshs{item.buying_price}
      </p>
      <div className="flex justify-between">
        <input
          value={quantityEntered}
          onBlur={(e) => {
            setquantityEntered(e.target.value);
            if (quantityEntered != undefined) {
              setProductList((prev) => {
                return [
                  ...prev,
                  {
                    item_id: item.id,
                    units: quantityEntered,
                    price: item.buying_price,
                    total_price: quantityEntered * item.buying_price,
                  },
                ];
              });
            }
          }}
          onChange={(e) => setquantityEntered(e.target.value)}
          placeholder="Enter Quantity"
          className="px-3 py-2 outline-none border-b"
          type="number"
        />
      </div>
    </div>
  );
}
