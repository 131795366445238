import React, { useEffect, useState } from "react";
import { AiFillFrown, AiTwotoneRightSquare } from "react-icons/ai";
import { WarningOutlined, StarFilled, StarTwoTone } from "@ant-design/icons";
import axios from "axios";
import { BASE_URI } from "../BASE_URI";
import { useAuth } from "../hooks/useAuth.tsx";
import { message } from "antd";

export default function IssueStock({ inventoryCategories, products }) {
  const [active, setActive] = useState(0);
  const [searchText, setSearchText] = useState("");

  let { loginDetails, getEmployee } = useAuth();

  // console.log(getEmployee);

  const category = inventoryCategories;

  const menu = getDisplayedData();

  function getDisplayedData() {
    if (searchText === "") {
      if (active != 0) {
        return (
          products.filter((product) => product.category_id == active) || []
        );
      } else {
        return products || [];
      }
    } else {
      return (
        products.filter((product) =>
          product.product_name.toLowerCase().includes(searchText.toLowerCase())
        ) || []
      );
    }
  }

  // const menu = [];

  const [selectedproducts, setselectedProducts] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalValue, settotalValue] = useState(0);
  const [sname, setName] = useState(loginDetails.EMPLOYEE_NAME);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const [filteredselectedproducts, setfilteredselectedProducts] = useState([]);

  let updateGroupings = () => {
    let groupingViaCommonProperty = Object.values(
      selectedproducts.reduce((acc, current) => {
        acc[current.product_name] = acc[current.product_name] ?? [];
        acc[current.product_name].push(current);
        return acc;
      }, {})
    );
    setfilteredselectedProducts(groupingViaCommonProperty);
  };

  let itemsFunction = (factor) => {
    let totals = 0;
    selectedproducts.map((product) => {
      if (factor) {
        totals = totals + 1;
      } else {
        totals = totals - 1;
      }
    });
    settotalValue(totals);
  };
  const [messageApi, contextHolder] = message.useMessage();
  const Toast = (message) => {
    messageApi.error(message);
  };

  const SuccessToast = (message) => {
    messageApi.success(message);
  };

  const searchFilterFunction = (text) => {
    setSearchText(text);
  };

  return (
    <div className="px-[2rem] ">
      <p className="text-[1.5rem] my-[1rem]">Issue Stock</p>
      <p className="text-[1rem] my-[1rem]">Categories</p>
      <div className="flex gap-x-[1rem] ">
        {category.map((c) => {
          return (
            <div
              onClick={() => {
                setActive(c.id);
              }}
              className={`${
                active == c.id ? "bg-[#2774E9] text-white" : "bg-[#EAEAEA]"
              } px-[1.6rem] py-[0.6rem] rounded-[1.3rem] cursor-pointer`}
            >
              {c.category_name}
            </div>
          );
        })}
        {category.length == 0 && (
          <div className="text-orange-500 flex justify-center gap-3 items-center">
            <WarningOutlined />
            <p>
              Your Inventory Has no Active category. Create a few using the Lisa
              mobile App
            </p>
          </div>
        )}
      </div>
      <div className="flex gap-x-3 mt-[1rem]">
        <div className="w-[75%]">
          <div>
            <input
              value={searchText}
              onChange={(e) => searchFilterFunction(e.target.value)}
              placeholder="Search"
              className="outline-none w-[90%] border-2 rounded-md bg-white px-3 py-1 justify-center ml-[1rem] "
            />
            <div className="grid grid-cols-2 mt-[1rem] max-h-[60vh]  overflow-x-hidden ">
              {menu.map((item) => {
                return (
                  <Item
                    item={item}
                    key={item.id}
                    updateGroupings={updateGroupings}
                    factor
                    itemsFunction={itemsFunction}
                    setselectedProducts={setselectedProducts}
                    setTotalQuantity={setTotalQuantity}
                  />
                );
              })}
              {menu.length == 0 && (
                <div className="h-[20rem] col-span-2 justify-center flex-col flex items-center">
                  <p className="text-[1.3rem]">You have no Products!</p>
                  <AiFillFrown className="text-[4rem] text-[#2774E9]" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-[25%]">
          <div className="flex w-full justify-between">
            <p className="text-[1.3rem] text-[#2774e9]">Summary</p>
            {/* <p className="text-[1.3rem]">KES 5,678</p> */}
          </div>
          <p className="text-[1.2rem] my-[1rem]">
            {filteredselectedproducts.length} Products ({totalValue} Items Sold)
          </p>
          <div className=" border flex flex-col justify-center  px-4 py-3">
            {contextHolder}
            <p className="my-3">Issue to</p>
            <input
              required
              value={sname}
              onChange={(e) => setName(e.target.value)}
              className="border outline-[#2774e9] px-2 py-2 mb-4"
            />
            <p className="my-3">Contact (optional)</p>
            <input
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
              className="border outline-[#2774e9] px-2 py-2 mb-4"
            />
          </div>
          {loading ? (
            <div className="bg-[#2774e9]/50 py-2 px-4 my-[3rem] flex justify-center text-white shadow-lg border-none rounded-[3rem] cursor-pointer">
              Issuing Stock...
            </div>
          ) : (
            <div
              onClick={() => {
                if (phone == "" || phone == "") {
                  Toast("Please provide a Supplier Name and Phone Number");
                  return;
                }

                if (selectedproducts.length == 0) {
                  Toast(
                    "You must select atleast one inventory product to complete a stock issue operation"
                  );
                  return;
                }

                let transactionProducts = [];
                selectedproducts.map((m) => {
                  transactionProducts.push({
                    item_id: m.id,
                    units: 1,
                    price: m.buying_price,
                    total_price: m.buying_price,
                  });
                });

                setLoading(true);
                axios
                  .post(
                    `${BASE_URI}/issue-stock/${loginDetails.BUSINESS_ID}`,
                    {
                      sm_details: JSON.stringify(transactionProducts),
                      employee_id: getEmployee,
                      user_id: getEmployee,
                      supplier_id: sname,
                      type: 0,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + loginDetails.TOKEN,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res.data);
                    transactionProducts = [];
                    setLoading(false);
                    // setstockSuccess(true);
                    SuccessToast("You have Successfully Issued Stock");
                    // window.location.reload(false);
                    setselectedProducts([]);
                    setName("");
                    setPhone("");
                    setfilteredselectedProducts([]);
                  })
                  .catch((err) => {
                    transactionProducts = [];
                    setLoading(false);
                    Toast("An error occured while Issuing Stock");
                    console.log(err);
                  });
              }}
              className="bg-[#2774e9] py-2 px-4 my-[3rem] flex justify-center text-white shadow-lg border-none rounded-[3rem] cursor-pointer"
            >
              Issue Stock
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Item({
  item,
  setselectedProducts,
  itemsFunction,
  updateGroupings,
  setTotalQuantity,
}) {
  let [count, setCount] = useState(0);

  let addItem = () => {
    setCount(count + 1);
    setTotalQuantity((prev) => prev + 1);
    updateState(item, true);
    updateGroupings();
    itemsFunction(true);
  };

  let RemoveItem = () => {
    setCount(count - 1);
    setTotalQuantity((prev) => prev - 1);
    updateState(item, false);
    updateGroupings();
    itemsFunction(false);
  };

  const updateState = (m, increment) => {
    setselectedProducts((prev) => {
      if (increment) {
        return [...prev, m];
      } else {
        const updatedProducts = prev.filter((product) => product.id !== m.id);
        return updatedProducts;
      }
    });
  };

  return (
    <div className="bg-[#fff] hover:shadow-lg hover:scale-101 py-4 px-4 transition-all duration-500  mx-3 my-2 border rounded-lg  ">
      <p>
        {item.product_name} @ Kshs{item.buying_price}
      </p>
      <div className="flex justify-between">
        {count > 0 ? (
          <div
            onClick={RemoveItem}
            className="bg-[rgba(204,233,255,1)] px-4 py-2 rounded-full cursor-pointer"
          >
            -
          </div>
        ) : (
          <div className="bg-[rgba(204,233,255,0.3)] px-4 py-2 rounded-full cursor-pointer">
            -
          </div>
        )}
        <div className=" px-4 py-2 rounded-[5rem] cursor-pointer">
          {count} Items
        </div>
        <div
          onClick={addItem}
          className="bg-[rgba(204,233,255,1)] px-4 py-2 rounded-full cursor-pointer"
        >
          +
        </div>
      </div>
    </div>
  );
}
