import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Select, message } from "antd";
import axios from "axios";
import { BASE_URI } from "../BASE_URI";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";

const AddBusiness = () => {
  // END OF REDIRECT
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [businessName, setbusinessName] = useState("");
  const [businessType, setbusinessType] = useState("Service Provider");

  let name = localStorage.getItem(LocalStorageKeys.ADMIN_NAME);
  let ID = localStorage.getItem(LocalStorageKeys.ADMIN_ID);
  let EMAIL = localStorage.getItem(LocalStorageKeys.ADMIN_EMAIL);
  let PHONE = localStorage.getItem(LocalStorageKeys.ADMIN_PHONE);
  let TOKEN = localStorage.getItem(LocalStorageKeys.TOKEN);

  const [messageApi, contextHolder] = message.useMessage();

  const create_business = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let res = await axios.post(
        BASE_URI + "/business",
        {
          business_name: businessName,
          sub_category: businessType,
          owner: name,
          user_id: ID,
          email: EMAIL,
          phone: PHONE,
          currency: "KES",
          operation_location: "Nairobi",
        },
        {
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
        }
      );
      console.log(res.data);
      setLoading(false);

      if (res.data.success == false) {
        messageApi.open({
          type: "error",
          content:
            "We encountered an error while creating a new business profile. Please try again later.",
        });
        return;
      }

      localStorage.setItem(LocalStorageKeys.ADDED_BUSINESS, res.data.jsonData.id);
      
      messageApi.open({
        type: "success",
        content: "business Added successfully",
      });
        navigate("/add-catalogue");
    } catch (e) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "an error occurred",
      });
      console.log(e);
    }
  };

  return (
    <>
      <section
        className="sign-up-in-section bg-dark ptb-60"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        {contextHolder}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8 col-12">
              <div className="logo-wrapper  justify-center flex items-center">
                <Link to="/" className="mb-4 d-block text-center">
                  {/* <img
                    src={require("../4.png")}
                    alt="logo"
                    className="img-fluid"
                  /> */}
                  <p className="text-[4rem]">lisa</p>
                </Link>
              </div>

              <div className="register-wrap p-5 bg-light shadow rounded-custom">
                <h1 className="h3">Create Your Business Profile</h1>
                <p className="text-muted">
                  Before we continue, we need to know a little about your
                  business
                </p>

                <div className="action-btns"></div>

                <form action="#" className="mt-4 register-form">
                  <div className="row">
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Business Name <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          value={businessName}
                          onChange={(e) => setbusinessName(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Business Name"
                          id="text"
                          aria-label="text"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="password" className="mb-1">
                        Business Type <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <Select
                          defaultValue="Service Provider"
                          style={{
                            width: "100%",
                          }}
                          onChange={(value) => setbusinessType(value)}
                          options={[
                            // {
                            //   value: "Retailer",
                            //   label: "Retailer",
                            // },
                            // {
                            //   value: "Restaurant",
                            //   label: "Restaurant",
                            // },
                            {
                              value: "Service Provider",
                              label: "Service Provider",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      {!loading ? (
                        <button
                          onClick={create_business}
                          //   type="submit"
                          className="btn bg-[rgba(39,116,233,1)] text-white mt-3 d-block w-100"
                        >
                          Create Business Profile
                        </button>
                      ) : (
                        <button
                          //   type="submit"
                          disabled
                          className="btn bg-[rgba(39,116,233,0.7)] text-white mt-3 d-block w-100"
                        >
                          Please Wait...
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddBusiness;
