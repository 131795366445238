import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { message } from "antd";
import axios from "axios";
import { BASE_URI } from "../BASE_URI";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";

const Verify = () => {
  const Loginuser = async (email, password) => {
    try {
      let res = await axios.post(`${BASE_URI}/login`, {
        email,
        password,
      });
      console.log(res.data);
      setLoading(false);

      localStorage.setItem(
        LocalStorageKeys.ADMIN_EMAIL,
        res.data.jsonData.email
      );
      localStorage.setItem(LocalStorageKeys.ADMIN_NAME, res.data.jsonData.name);
      localStorage.setItem(LocalStorageKeys.TOKEN, res.data.token);
      localStorage.setItem(LocalStorageKeys.ADMIN_ID, res.data.jsonData.id);
      localStorage.setItem(LocalStorageKeys.ADMIN_PHONE, res.data.jsonData.phone);
      //   localStorage.setItem(
      //     LocalStorageKeys.BUSINESS_ID,
      //     res.data.business[0].id
      //   );
      //   localStorage.setItem(
      //     LocalStorageKeys.BUSINESS_NAME,
      //     res.data.business[0].business_name
      //   );
      //   navigate("/inventory");
    //   console.log("loggef in successfully");
    navigate('/create-business');
    } catch (e) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "error occured",
      });
      console.log(e);
    }
  };

  // END OF REDIRECT
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [otp, setOtp] = useState("");
  //   const [email, setEmail] = useState("");
  const verifyOtp = async () => {
    if (otp == "") {
      messageApi.open({
        type: "error",
        content: "Provide an OTP to proceed",
      });
      return;
    }
    setLoading(true);
    let email_stored = await localStorage.getItem(LocalStorageKeys.ADMIN_EMAIL);
    let password_stored = await localStorage.getItem(
      LocalStorageKeys.ADMIN_PASSWORD
    );
    try {
      let res = await axios.post(`${BASE_URI}/verify-account`, {
        email: email_stored,
        otp,
      });
      console.log(res.data);
      setLoading(false);

      if (res.data.success == false) {
        messageApi.open({
          type: "error",
          content: "Wrong OTP provided",
        });
        return;
      }

      //   navigate("/");
      Loginuser(email_stored, password_stored);

    } catch (e) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "Wrong login credentials",
      });
      console.log(e);
    }
  };

  return (
    <>
      <section
        className="sign-up-in-section bg-dark ptb-60"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        {contextHolder}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8 col-12">
              <div className="logo-wrapper  justify-center flex items-center">
                <Link to="/" className="mb-4 d-block text-center">
                  {/* <img
                    src={require("../4.png")}
                    alt="logo"
                    className="img-fluid"
                  /> */}
                  <p className="text-[4rem]">lisa</p>
                </Link>
              </div>

              <div className="register-wrap p-5 bg-light shadow rounded-custom">
                <h1 className="h3">Verify your Email Address</h1>
                <p className="text-muted">
                  We will need to verify your email address. We have therefore
                  sent an One Time Password to your email.
                </p>

                <div className="action-btns"></div>

                <form action="#" className="mt-4 register-form">
                  <div className="row">
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        OTP <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          type="number"
                          className="form-control"
                          placeholder="One Time Password"
                          id="otp"
                          aria-label="otp"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      {!loading ? (
                        <button
                          onClick={verifyOtp}
                          //   type="submit"
                          className="btn bg-[rgba(39,116,233,1)] text-white mt-3 d-block w-100"
                        >
                          Verify
                        </button>
                      ) : (
                        <button
                          //   type="submit"
                          disabled
                          className="btn bg-[rgba(39,116,233,0.7)] text-white mt-3 d-block w-100"
                        >
                          Verifying...
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Verify;
