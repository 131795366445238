import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";

import IssueStock from "../components/IssueStock";

import ReceiveStock from "../components/ReceiveStock";
import axios from "axios";
import { useAuth } from "../hooks/useAuth.tsx";
import { WarningOutlined, StarFilled, StarTwoTone } from "@ant-design/icons";

import { BASE_URI } from "../BASE_URI";
import NewProductForm from "../components/NewProductForm.tsx";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";

export default function Inventory() {
  const [issueStockModal, setissueStockModal] = useState(false);
  const [receiveStockModal, setreceiveStockModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [totalWorth, setTotalWorth] = useState(0);
  const [inventoryCategories, setInventoryCategories] = useState([]);
  let { loginDetails } = useAuth();

  let created_business = localStorage.getItem(LocalStorageKeys.ADDED_BUSINESS);
  let token = localStorage.getItem(LocalStorageKeys.TOKEN);

  let businessId = loginDetails.BUSINESS_ID || created_business;
  let token_to_use = loginDetails.TOKEN || token;

  useEffect(() => {
    setProducts([]);
    axios
      .get(`${BASE_URI}/list-products/${businessId}`, {
        headers: {
          Authorization: `Bearer ${token_to_use}`,
        },
      })
      .then((res) => {
        setProducts(res.data.jsonData);
        // setTotalUnites
        let totals = 0;
        let totalWorth = 0;
        res.data.jsonData.map((item) => {
          totals = totals + Number(item.stock);
          totalWorth =
            totalWorth + Number(item.buying_price) * Number(item.stock);
        });
        setTotalUnits(totals);
        setTotalWorth(totalWorth);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Collumns = [
    {
      title: "Service Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Duration (min)",
      dataIndex: "stock",
      key: "stock",
    },
    {
      title: "Cost (KES)",
      dataIndex: "selling_price",
      key: "selling_price",
    },
    // {
    //   title: "Value",
    //   // dataIndex: "",
    //   key: "",
    //   render: (text, record) => {
    //     return <p>{record.stock * record.buying_price}</p>;
    //   },
    // },
  ];

  useEffect(() => {
    axios
      .get(`${BASE_URI}/category/${businessId}`, {
        headers: {
          Authorization: "Bearer " + token_to_use,
        },
      })
      .then((res) => {
        let inventory_cats = res.data.jsonData.filter((cat) => {
          return cat.category_type == "inventory";
        });
        setInventoryCategories(inventory_cats);
      })
      .catch((err) => {});
  }, []);

  function getDisplayedData() {
    if (searchText === "") {
      return products || [];
    } else {
      return (
        products.filter((product) =>
          product.product_name.toLowerCase().includes(searchText.toLowerCase())
        ) || []
      );
    }
  }
  const [searchText, setSearchText] = useState("");

  const searchFilterFunction = (text) => {
    setSearchText(text);
  };

  function commaSeparator(num: number) {
    if (num !== undefined) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return num;
    }
  }

  const [newProductModal, setnewProductModal] = useState(false);
  return (
    <div className="hidden md:block px-[1.3rem] h-[40rem]">
      {/* <p className="text-[1.2rem] my-[1rem]">Categories</p> */}

      <div className="flex gap-x-3 my-[1rem]">
        <div className="w-[75%]">
          <div>
            <input
              value={searchText}
              onChange={(e) => searchFilterFunction(e.target.value)}
              placeholder="Search"
              className="outline-none w-full border-2 rounded-md bg-white px-3 py-2"
            />
            <div className="text-orange-500">
              <div className="flex my-3 items-center gap-2">
                <WarningOutlined />
                <p> use mobile to view stock movement</p>
              </div>
            </div>
            <div className="flex gap-x-[1rem] my-[1rem] ">
              {/* <div
                onClick={() => {
                  setreceiveStockModal(true);
                }}
                className={`${"bg-[rgba(0,198,103,1)] text-white"} px-[1.6rem] py-[0.6rem] rounded-[1.3rem] cursor-pointer`}
              >
                RECEIVE STOCK
              </div>
              <div
                onClick={() => setissueStockModal(true)}
                className={`${"bg-[rgba(233,120,39,1)] text-white"} px-[1.6rem] py-[0.6rem] rounded-[1.3rem] cursor-pointer`}
              >
                ISSUE STOCK
              </div> */}
              <div
                onClick={() => setnewProductModal(true)}
                className={`${"bg-[#2774E9] text-white"} px-[1.6rem] py-[0.6rem] rounded-[1.3rem] cursor-pointer`}
              >
                NEW SERVICE
              </div>
            </div>
            <div className="">
              <Table
                size="middle"
                columns={Collumns}
                dataSource={getDisplayedData()}
              />
            </div>

            {/* issue stock */}
            <Modal
              style={{ top: 20 }}
              onCancel={() => setissueStockModal(false)}
              footer={null}
              destroyOnClose={true}
              width={"80%"}
              open={issueStockModal}
            >
              <IssueStock
                products={products}
                inventoryCategories={inventoryCategories}
              />
            </Modal>
            <Modal
              style={{ top: 20 }}
              onCancel={() => setnewProductModal(false)}
              footer={null}
              destroyOnClose={true}
              width={"30%"}
              open={newProductModal}
            >
              <NewProductForm categories={inventoryCategories} />
            </Modal>
            <Modal
              style={{ top: 20 }}
              onCancel={() => setreceiveStockModal(false)}
              footer={null}
              width={"80%"}
              open={receiveStockModal}
              destroyOnClose={true}
            >
              <ReceiveStock
                categories={inventoryCategories}
                products={products}
              />
            </Modal>

            {/* end issue stpcl */}
          </div>
        </div>
        <div className="w-[25%]">
          <div className="border w-1/2 m-auto rounded-lg pl-4 pt-3 my-2 flex flex-col justify-center">
            <p>Available Services</p>
            <p className="text-[2rem]">{commaSeparator(products.length)}</p>
          </div>
          {/* <div className="border w-1/2 m-auto rounded-lg pl-4 pt-3 my-2 flex flex-col justify-center">
            <p>Total Units</p>
            <p className="text-[2rem]">{commaSeparator(totalUnits)}</p>
          </div>
          <div className="border w-1/2 m-auto rounded-lg pl-4 pt-3 my-2 flex flex-col justify-center">
            <p>Net Worth</p>
            <p className="text-[1.2rem] font-bold">
              KES {commaSeparator(totalWorth)}
            </p>
          </div> */}

          {/* <div className="border w-[19rem] bg-[rgba(244,244,244,1)] fixed bottom-[2rem] right-[7rem] m-auto rounded-lg px-4 py-3 flex flex-col justify-center">
            <p className="text-[1.2rem] font-bold">Use App</p>
            <p className="">To see stock movement and more</p>
            <div className="px-[3rem] py-[0.7rem] w-[9rem] text-white flex justify-center rounded-[10rem] cursor-pointer my-[1rem] bg-[#2774e9] items-center">
              Download
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
