import { Select, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { BASE_URI } from "../BASE_URI";
import { useAuth } from "../hooks/useAuth.tsx";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";

export default function NewProductForm({ categories }) {
  const handleCategoryChange = (value) => {
    // console.log(`selected ${value}`);
    setcategoryId(value);
  };

  let { loginDetails } = useAuth();
  let categoryOption = [];

  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  categories.map((category) => {
    categoryOption.push({
      value: category.id,
      label: category.category_name,
    });
  });
  const Toast = (message) => {
    messageApi.error(message);
  };

  const SuccessToast = (message) => {
    messageApi.success(message);
  };
  const addProductHandler = () => {
    setLoading(true);
    axios
      .post(
        `${BASE_URI}/add-product`,
        {
          category_id: 1,
          product_name: productName,
          buying_price: 0,
          whole_sale_buying_price: 0,
          whole_sale_selling_price: 0,
          selling_price: costPrice,
          color: "null",
          brand: "null",
          brand_type: "service",
          stock: Number(Quantity),
          business_id:
            localStorage.getItem(LocalStorageKeys.BUSINESS_ID) ||
            localStorage.getItem(LocalStorageKeys.ADDED_BUSINESS),
        },
        {
          headers: {
            Authorization: "Bearer " + loginDetails.TOKEN,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        if (response.data.success) {
          SuccessToast("Successfully added a ser vice!");
          setproductName("");
          setcostPrice("");
          setcategoryId("");
          setQuantity(0);
          setSelling("");
        } else {
          Toast(
            "We encountered an error while completing your request! please try again"
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        Toast("Failed to add service to the inventory!");
        console.log(err);
      });
  };
  //   form event handlers
  const [productName, setproductName] = useState("");
  const [costPrice, setcostPrice] = useState("");
  const [categoryId, setcategoryId] = useState("");
  const [Quantity, setQuantity] = useState(0);
  const [sellingPrice, setSelling] = useState("");
  return (
    <div className="flex flex-col justify-center">
      {contextHolder}
      <p className="text-[1.3rem] my-2 ">Create New Service</p>
      <div className="my-2">
        <p>Service Name</p>
        <input
          value={productName}
          onChange={(e) => setproductName(e.target.value)}
          className="border px-3 py-2 mt-2 outline-[#2774E9] w-full "
        />
      </div>
      <div className="my-2">
        <p>Duration</p>
        <input
          type="number"
          value={Quantity}
          onChange={(e) => setQuantity(e.target.value)}
          className="border px-3 py-2 mt-2 outline-[#2774E9] w-full "
        />
      </div>{" "}
      <div className="my-2">
        <p>Cost</p>
        <input
          value={costPrice}
          onChange={(e) => setcostPrice(e.target.value)}
          type="number"
          className="border px-3 py-2 mt-2 outline-[#2774E9] w-full "
        />
      </div>
      {/* <div className="my-2">
        <p>Selling Price</p>
        <input
          value={sellingPrice}
          onChange={(e) => setSelling(e.target.value)}
          type="number"
          className="border px-3 py-2 mt-2 outline-[#2774E9] w-full "
        />
      </div> */}
      {/* <div className="my-2">
        <p>Category</p>
        <Select
          className="mt-3 "
          defaultValue="Choose a Category"
          style={{
            width: "100%",
          }}
          onChange={handleCategoryChange}
          options={categoryOption}
        />
      </div> */}{" "}
      <div className="my-2">
        <p>Category</p>
        <Select
          className="mt-3 "
          defaultValue="Choose a Category"
          style={{
            width: "100%",
          }}
          onChange={handleCategoryChange}
          options={categoryOption}
        />
      </div>
      {loading ? (
        <div className="bg-[#2774e9]/60 py-2 px-4 my-[3rem] flex justify-center text-white shadow-lg border-none rounded-[3rem] cursor-pointer">
          Creating Service...
        </div>
      ) : (
        <div
          onClick={addProductHandler}
          className="bg-[#2774e9] py-2 px-4 my-[3rem] flex justify-center text-white shadow-lg border-none rounded-[3rem] cursor-pointer"
        >
          Create Service
        </div>
      )}
    </div>
  );
}
