import React from "react";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";

export function useAuth() {


  let getEmployee = localStorage.getItem(LocalStorageKeys.EMPLOYEE);
  let setEmployee = (e) => {
    localStorage.setItem(LocalStorageKeys.EMPLOYEE, e.id);
    localStorage.setItem(LocalStorageKeys.EMPLOYEE_NAME, e.name);
    console.log("setEmployee successful");
  };
  let logoutEmployee = (key) => {
    localStorage.removeItem(key);
  };

  let loginDetails = {
    ADMIN_EMAIL: localStorage.getItem(LocalStorageKeys.ADMIN_EMAIL),
    ADMIN_NAME: localStorage.getItem(LocalStorageKeys.ADMIN_NAME),
    BUSINESS_ID: localStorage.getItem(LocalStorageKeys.BUSINESS_ID),
    BUSINESS_NAME: localStorage.getItem(LocalStorageKeys.BUSINESS_NAME),
    TOKEN: localStorage.getItem(LocalStorageKeys.TOKEN),
    EMPLOYEE_NAME: localStorage.getItem(LocalStorageKeys.EMPLOYEE_NAME),
  };
  return { getEmployee, setEmployee, loginDetails, logoutEmployee };
}

