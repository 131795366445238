import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Select, message } from "antd";
import axios from "axios";
import { BASE_URI } from "../BASE_URI";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";

const AddCatalogue = () => {
  // END OF REDIRECT
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [productName, setproductName] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");

  let name = localStorage.getItem(LocalStorageKeys.ADMIN_NAME);
  let ID = localStorage.getItem(LocalStorageKeys.ADMIN_ID);
  let EMAIL = localStorage.getItem(LocalStorageKeys.ADMIN_EMAIL);
  let PHONE = localStorage.getItem(LocalStorageKeys.ADMIN_PHONE);
  let TOKEN = localStorage.getItem(LocalStorageKeys.TOKEN);

  const [messageApi, contextHolder] = message.useMessage();

  const addItem = async (e) => {
    let businessID = localStorage.getItem(LocalStorageKeys.ADDED_BUSINESS);
    e.preventDefault();
    setLoading(true);
    try {
      let res = await axios.post(
        BASE_URI + "/add-product",
        {
          product_name: productName,
          category_id: 1,
          buying_price: "0", //data.buyingPrice,
          selling_price: price,
          whole_sale_selling_price: "0",
          whole_sale_buying_price: "0",
          stock: duration,
          business_id: businessID,
          color: "none",
          brand: "none",
          brand_type: "service",
        },
        {
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
        }
      );
      console.log(res.data);
      setLoading(false);

      if (res.data.success == false) {
        messageApi.open({
          type: "error",
          content:
            "We encountered an error while creating a new business profile. Please try again later.",
        });
        return;
      }
      messageApi.open({
        type: "success",
        content: "Service Added successfully",
      });
      //   navigate("/inventory");
    } catch (e) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "an error occurred",
      });
      console.log(e);
    }
  };

  return (
    <>
      <section
        className="sign-up-in-section bg-dark ptb-60"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        {contextHolder}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8 col-12">
              <div className="logo-wrapper  justify-center flex items-center">
                <Link to="/" className="mb-4 d-block text-center">
                  {/* <img
                    src={require("../4.png")}
                    alt="logo"
                    className="img-fluid"
                  /> */}
                  <p className="text-[4rem]">lisa</p>
                </Link>
              </div>

              <div className="register-wrap p-5 bg-light shadow rounded-custom">
                <h1 className="h3">Add Your Services</h1>
                {/* <p className="text-muted">
                  You can add the Items in your Catalogue here.
                </p> */}

                <p className="text-muted">You can add your services here.</p>

                <div className="action-btns"></div>

                <form action="#" className="mt-4 register-form">
                  <div className="row">
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Service Name <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          value={productName}
                          onChange={(e) => setproductName(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Service Name"
                          id="text"
                          aria-label="text"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Duration (Minutes){" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                          type="number"
                          className="form-control"
                          placeholder="Duration in Minutes"
                          id="text"
                          aria-label="text"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Price <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          type="number"
                          className="form-control"
                          placeholder="Service Pricing"
                          id="text"
                          aria-label="text"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      {!loading ? (
                        <button
                          onClick={addItem}
                          //   type="submit"
                          className="btn bg-[rgba(39,116,233,1)] text-white mt-3 d-block w-100"
                        >
                          Add Service
                        </button>
                      ) : (
                        <button
                          //   type="submit"
                          disabled
                          className="btn bg-[rgba(39,116,233,0.7)] text-white mt-3 d-block w-100"
                        >
                          Please Wait...
                        </button>
                      )}
                    </div>
                  </div>
                  <p className="font-monospace fw-medium text-center text-muted mt-3 pt-4 mb-0">
                    Done adding Service Items? <br></br>
                    <Link
                      to="/add-time"
                      className="text-decoration-none text-blue-600 m-3"
                    >
                      Continue Onboarding
                    </Link>
                    <br />
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddCatalogue;
