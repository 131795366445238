import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Checkbox, message } from "antd";
import axios from "axios";
import { BASE_URI } from "../BASE_URI";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";
import { useAuth } from "../hooks/useAuth.tsx";

const AddTiming = () => {
  const [messageApi, contextHolder] = message.useMessage();

  // END OF REDIRECT
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [mon, setMon] = useState(0);
  const [tue, setTue] = useState(0);
  const [wed, setWed] = useState(0);
  const [thur, setThur] = useState(0);
  const [Fri, setFri] = useState(0);
  const [sart, setsart] = useState(0);
  const [Sun, setSun] = useState(0);

  const confirmAvailability = async (e) => {
    e.preventDefault();
    let available_days = `${mon}${tue}${wed}${thur}${Fri}${sart}${Sun}`;
    let business_id =
      localStorage.getItem(LocalStorageKeys.BUSINESS_ID) ||
      localStorage.getItem(LocalStorageKeys.ADDED_BUSINESS);

    try {
      setLoading(true);
      await axios.post(`${BASE_URI}/add/service/workdays/${business_id}`, {
        workdays: available_days,
      });
      setLoading(false);

      navigate("/inventory");
    } catch (e) {
      setLoading(false);

      console.log(e);
    }

    // console.log(available_days);
  };

  return (
    <>
      <section
        className="sign-up-in-section bg-dark ptb-60"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        {contextHolder}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8 col-12">
              <div className="logo-wrapper  justify-center flex items-center">
                <Link to="/" className="mb-4 d-block text-center">
                  {/* <img
                    src={require("../4.png")}
                    alt="logo"
                    className="img-fluid"
                  /> */}
                  <p className="text-[4rem]">lisa</p>
                </Link>
              </div>

              <div className="register-wrap p-5 bg-light shadow rounded-custom">
                <h1 className="h3">
                  One Last Thing! Tell us your working hours
                </h1>
                <p className="text-muted">
                  This will help clients make a service reservation within the
                  days you select.
                </p>

                <div className="action-btns"></div>

                <form action="#" className="mt-4 register-form">
                  <div className="row">
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Select Open Days: <span className="text-danger">*</span>
                      </label>
                    </div>
                    <Checkbox onChange={() => setMon(1)}>
                      <div className="text-black">Monday</div>
                    </Checkbox>
                    <Checkbox onChange={() => setTue(1)}>
                      <div className="text-black">Tuesday</div>
                    </Checkbox>{" "}
                    <Checkbox onChange={() => setWed(1)}>
                      <div className="text-black">Wednesday</div>
                    </Checkbox>
                    <Checkbox onChange={() => setThur(1)}>
                      <div className="text-black">Thursday</div>
                    </Checkbox>{" "}
                    <Checkbox onChange={() => setFri(1)}>
                      <div className="text-black">Friday</div>
                    </Checkbox>
                    <Checkbox onChange={() => setsart(1)}>
                      <div className="text-black">Sartuday</div>
                    </Checkbox>{" "}
                    <Checkbox onChange={() => setSun(1)}>
                      <div className="text-black">Sunday</div>
                    </Checkbox>
                    <div className="col-12">
                      {!loading ? (
                        <button
                          onClick={confirmAvailability}
                          //   type="submit"
                          className="btn bg-[rgba(39,116,233,1)] text-white mt-3 d-block w-100"
                        >
                          Confirm Availability
                        </button>
                      ) : (
                        <button
                          //   type="submit"
                          disabled
                          className="btn bg-[rgba(39,116,233,0.7)] text-white mt-3 d-block w-100"
                        >
                          Please wait...
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddTiming;
