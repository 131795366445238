import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { message } from "antd";
import axios from "axios";
import { BASE_URI } from "../BASE_URI";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";

const Register = () => {
  // END OF REDIRECT
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");

  //

  //
  const register = async (e) => {
    e.preventDefault();
    if (email == "" || password == "" || phone == "" || name == "") {
      messageApi.open({
        type: "error",
        content: "All fields must be provided",
      });
      return;
    }
    if (password != password_confirmation) {
      messageApi.open({
        type: "error",
        content: "Your password and confirmation password are not the same",
      });
      return;
    }
    setLoading(true);
    try {
      let res = await axios.post(`${BASE_URI}/register`, {
        email,
        password,
        password_confirmation: password,
        phone,
        name,
      });
      console.log(res.data);
      //   setLoading(false);

      if (res.data.success == false) {
      setLoading(false);

        messageApi.open({
          type: "error",
          content:
            "The email address is already in use",
        });
        return;
      }

    //   Loginuser(email, password);
    localStorage.setItem(LocalStorageKeys.ADMIN_EMAIL, email);
    localStorage.setItem(LocalStorageKeys.ADMIN_PASSWORD, password);


       navigate("/verify");
    } catch (e) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content:
          "An error occurred while creating an account! Try again or contact support if the problem persists",
      });
      console.log(e);
    }
  };

  return (
    <>
      <section
        className="sign-up-in-section bg-dark ptb-60"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        {contextHolder}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8 col-12">
              <div className="logo-wrapper  justify-center flex items-center">
                <Link to="/" className="mb-4 d-block text-center">
                  {/* <img
                    src={require("../4.png")}
                    alt="logo"
                    className="img-fluid"
                  /> */}
                  <p className="text-[4rem]">lisa</p>
                </Link>
              </div>

              <div className="register-wrap p-5 bg-light shadow rounded-custom">
                <h1 className="h3">Get Started</h1>
                <p className="text-muted">
                  Create an account to access your account web-enabled methods
                  of innovative niches.
                </p>

                <div className="action-btns"></div>

                <form action="#" className="mt-4 register-form">
                  <div className="row">
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Name <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="name"
                          className="form-control"
                          placeholder="Name"
                          id="name"
                          aria-label="name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Phone <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="number"
                          className="form-control"
                          placeholder="Phone Number"
                          id="phone"
                          aria-label="phone"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          id="email"
                          aria-label="email"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="password" className="mb-1">
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          placeholder="Password"
                          id="password"
                          aria-label="Password"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="password" className="mb-1">
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          value={password_confirmation}
                          onChange={(e) =>
                            setpassword_confirmation(e.target.value)
                          }
                          className="form-control"
                          placeholder="Password"
                          id="password"
                          aria-label="Password"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      {!loading ? (
                        <button
                          onClick={register}
                          //   type="submit"
                          className="btn bg-[rgba(39,116,233,1)] text-white mt-3 d-block w-100"
                        >
                          Create Account
                        </button>
                      ) : (
                        <button
                          //   type="submit"
                          disabled
                          className="btn bg-[rgba(39,116,233,0.7)] text-white mt-3 d-block w-100"
                        >
                          Please wait...
                        </button>
                      )}
                    </div>
                  </div>
                  <p className="font-monospace fw-medium text-center text-muted mt-3 pt-4 mb-0">
                    Already have an account?
                    <Link to="/" className="text-decoration-none">
                      Sign In
                    </Link>
                    <br />
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
