import React, { useEffect, useState } from "react";
import { LuAlignJustify, LuBuilding2, LuBookOpenCheck } from "react-icons/lu";
import { BiWallet } from "react-icons/bi";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { BiArchiveIn, BiBookOpen } from "react-icons/bi";
import { BsGraphUpArrow } from "react-icons/bs";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";
import { useAuth } from "../hooks/useAuth.tsx";
import { Modal, notification, Alert, message } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { BASE_URI } from "../BASE_URI";
import { AiOutlineCopy } from "react-icons/ai";
export default function SideBar() {
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  let { getEmployee, setEmployee, loginDetails, logoutEmployee } = useAuth();

  let created_business = localStorage.getItem(LocalStorageKeys.ADDED_BUSINESS);
  let token = localStorage.getItem(LocalStorageKeys.TOKEN);

  let businessId = loginDetails.BUSINESS_ID || created_business;
  let token_to_use = loginDetails.TOKEN || token;

  const menus = [
    // {
    //   title: "Business Manager",
    //   path: "#",
    //   src: <LuBuilding2 />,
    // },
    // {
    //   title: "My Menu",
    //   path: "/menu",
    //   src: <LuBookOpenCheck />,
    // },
    {
      title: "Orders & Payments",
      path: "/orders",
      src: <BiWallet />,
    },
    {
      title: "Inventory",
      path: "/inventory",
      src: <BsGraphUpArrow />,
    },
  ];
  function GetGreetings(hour) {
    if (hour < 12) {
      return "Good Morning ";
    } else if (hour < 16) {
      return "Good Afternoon ";
    } else {
      return "Good Evening ";
    }
  }

  // const menu = [];
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [loadingEmployee, setloadingEmployee] = useState(true);
  useEffect(() => {
    axios
      .get(`${BASE_URI}/list/employee/${businessId}`, {
        headers: {
          Authorization: "Bearer " + token_to_use,
        },
      })
      .then((response) => {
        setEmployees(response.data.business);
        setloadingEmployee(false);
      })
      .catch((error) => {
        setloadingEmployee(false);

        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (getEmployee == undefined && employees.length != 0) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
    // CHECK ADMIN AUTHORIZATION
    if (loginDetails.TOKEN == undefined) {
      navigate("/");
    }
  }, []);

  let logoutEmployeeHandler = () => {
    logoutEmployee(LocalStorageKeys.EMPLOYEE);
    window.location.reload(false);
  };

  const [employees, setEmployees] = useState([]);
  const [id, setid] = useState([]);

  // useEffect(() => {
  //   let id_to_use = localStorage.getItem(LocalStorageKeys.BUSINESS_ID);
  //   if (id_to_use == undefined || id_to_be == undefined) {
  //     id_to_use = localStorage.getItem(LocalStorageKeys.ADDED_BUSINESS);
  //   }
  //   setid(id_to_use);
  // }, []);
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <div className="flex flex-auto">
      {contextHolder}
      <div
        className={`${
          open ? "w-60" : "w-fit"
        }  border-[#E0E0E0] pr-[1rem]  border-r animate__animated animate__bounce min-h-screen p-[1rem]`}
      >
        <div className="flex gap-x-[1rem] text-center items-center">
          {/* <LuAlignJustify
            className="text-[1.4rem] cursor-pointer"
            // onClick={() => setOpen(!open)}
          /> */}
          <div
            className={`text-[#2774E9] text-[1.4rem] font-bold text-center ${
              open ? "hidden" : "block"
            }`}
          >
            lisa
          </div>
        </div>
        <div>
          <ul>
            {menus.map((menu) => {
              return (
                <Link to={menu.path}>
                  <li
                    className={`my-[1rem]  flex gap-x-[1rem] ${
                      location.pathname === menu.path && "text-[#2774E9]"
                    }`}
                  >
                    <div
                      className={`text-[1.5rem] my-2 ${
                        open ? "hidden" : "block"
                      }`}
                    >
                      {menu.src}
                    </div>
                    <div
                      className={`text-[1rem] ${!open ? "hidden" : "block"}`}
                    >
                      {menu.title}
                    </div>
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
        <div className="fixed bottom-1">
          <div>
            <div>
              <BiArchiveIn
                className={`my-1 text-[1.5rem] cursor-pointer ${
                  open ? "hidden" : "block"
                }`}
              />
            </div>
            <div
              className={`my-1 cursor-pointer ${!open ? "hidden" : "block"}`}
            >
              Data Source
            </div>
          </div>
          <BiBookOpen
            className={`my-4 text-[1.5rem]  cursor-pointer ${
              open ? "hidden" : "block"
            }`}
          />

          <div className={`my-1 cursor-pointer ${!open ? "hidden" : "block"}`}>
            Articles
          </div>
        </div>
      </div>
      <div className="grow">
        <div className="my-3 ml-3 flex justify-between">
          <div className="text-[1.4rem]">
            <div className="flex">
              {GetGreetings(new Date().getHours())},
              <p className="ml-[4rem]">Your Shop Link: </p>{" "}
              <CopyToClipboard
                text={`https://app.phindor.com/${
                  localStorage.getItem(LocalStorageKeys.BUSINESS_ID) ||
                  localStorage.getItem(LocalStorageKeys.ADDED_BUSINESS)
                }`}
                onCopy={() => {}}
              >
                <div className="ml-2 mt-1 flex text-blue-500 text-[0.9rem]">
                  {`https://app.phindor.com/${
                    localStorage.getItem(LocalStorageKeys.BUSINESS_ID) ||
                    localStorage.getItem(LocalStorageKeys.ADDED_BUSINESS)
                  }`}
                  <AiOutlineCopy
                    onClick={() => {
                      messageApi.success("catalogue link copied to clipboard!");
                    }}
                    className=" ml-2 cursor-pointer text-[1.4rem]"
                  />
                </div>
              </CopyToClipboard>
            </div>

            <p className="text-[1rem] md:hidden text-orange-500">
              Only available on Desktop!
            </p>
          </div>
          <div className="flex ">
            {/* <div className="px-[1.4rem] flex justify-center items-center mr-[3rem] py-[0.6rem] rounded-[2rem] cursor-pointer text-[rgba(39,116,233,1)] bg-[rgba(224,224,224,1)]">
              NEW MENU ITEM
            </div> */}
            {getEmployee != undefined && (
              <div
                onClick={logoutEmployeeHandler}
                className="px-[1.4rem] flex justify-center items-center mr-[3rem] py-[0.6rem] rounded-[2rem] cursor-pointer text-[rgba(233,62,39,1)] bg-[rgba(255,225,221,1)]"
              >
                LOGOUT
              </div>
            )}
          </div>
        </div>

        <LoginModal
          loadingEmployee={loadingEmployee}
          setloadingEmployee={setloadingEmployee}
          employees={employees}
          handleCancel={handleCancel}
          handleOk={handleOk}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        <Outlet />
      </div>
    </div>
  );
}

function LoginModal({
  isModalOpen,
  setIsModalOpen,
  handleOk,
  loadingEmployee,
  setloadingEmployee,
  handleCancel,
  employees,
}) {
  const [selectedEmployee, setselectedEmployee] = useState(0);
  let { getEmployee, setEmployee, logoutEmployee } = useAuth();

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Authorization Failed!",
      description:
        "Sounds like you are not authorized with the provided credentials",
    });
  };

  let loginEmployee = () => {
    setLoading(true);
    axios
      .post(`${BASE_URI}/login/employee`, {
        email: selectedEmployee.email,
        password: password,
      })
      .then((response) => {
        // console.log(response.data);
        setLoading(false);

        if (response.data.success) {
          setEmployee(selectedEmployee);
          setIsModalOpen(false);
        } else {
          openNotificationWithIcon("error");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  let LogoutEmployee = () => {
    logoutEmployee(LocalStorageKeys.EMPLOYEE);
  };

  return (
    <div>
      <Modal
        width={"40%"}
        footer={null}
        title="Who's This?"
        open={isModalOpen}
        onOk={handleOk}
        closable={false}
        className="hidden md:block"
        // onCancel={handleCancel}
      >
        {contextHolder}
        <div className="my-[1rem] hidden md:grid md:grid-cols-5 ">
          {employees.map((employee) => {
            return (
              <div
                onClick={() => {
                  setselectedEmployee(employee);
                  setEmployee(employee.id);
                }}
                className={`py-[1rem]  flex justify-center cursor-pointer my-2 rounded-[3rem] mx-2 ${
                  selectedEmployee.id == employee.id
                    ? "bg-[rgba(39,116,233,1)] text-white"
                    : "bg-[rgba(217,217,217,1)]"
                } `}
              >
                {employee.name}
              </div>
            );
          })}
        </div>
        {loadingEmployee && (
          <div className="text-center  text-orange-500">
            <SyncOutlined spin={true} />{" "}
            <p className="ml-2">Getting Employee Accounts...</p>
          </div>
        )}

        <div className="my-[2rem] flex flex-col justify-center items-center ">
          <div className="text-center font-bold text-[1.4rem]">
            Enter Password
          </div>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border px-2 py-3 outline-[rgba(39,116,233,1)] my-3"
            type="password"
          />
          {selectedEmployee != 0 ? (
            <div>
              {loading ? (
                <div className="bg-[rgba(39,116,233,0.5)] px-[7.4rem] py-[1rem] rounded-[2rem] shadow-lg text-white cursor-pointer mt-[4rem]">
                  Verifying...
                </div>
              ) : (
                <div
                  onClick={loginEmployee}
                  className="bg-[rgba(39,116,233,1)] px-[7.4rem] py-[1rem] rounded-[2rem] shadow-lg text-white cursor-pointer mt-[4rem]"
                >
                  Proceed
                </div>
              )}
            </div>
          ) : (
            <div className="text-orange-500 italic">
              NOTE: Please select one account to proceed!
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
