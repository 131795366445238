const LocalStorageKeys = {
  EMPLOYEE: "EMPLOYEE",
  ADMIN_EMAIL: "ADMIN_EMAIL",
  BUSINESS_ID: "BUSINESS_ID",
  BUSINESS_NAME: "BUSINESS_NAME",
  ADMIN_NAME: "ADMIN_NAME",
  TOKEN: "TOKEN",
  EMPLOYEE_NAME:"EMPLOYEE_NAME",
  ADMIN_PASSWORD:"ADMIN_PASSWORD",
  ADMIN_ID:"ADMIN_ID",
  ADMIN_PHONE:"ADMIN_PHONE",
  ADDED_BUSINESS:"ADDED_BUSINESS"
};

export { LocalStorageKeys };
