import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { message } from "antd";
import axios from "axios";
import { BASE_URI } from "../BASE_URI";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";
import { useAuth } from "../hooks/useAuth.tsx";

const SignIn = () => {
  let { loginDetails } = useAuth();
  useEffect(() => {
    if (
      loginDetails.BUSINESS_ID == undefined ||
      loginDetails.ADMIN_NAME == undefined ||
      loginDetails.ADMIN_EMAIL == undefined ||
      loginDetails.BUSINESS_NAME == undefined
    ) {
      navigate("/");
    }
  }, []);
  // END OF REDIRECT
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = async () => {
    if (email == "" || password == "") {
      messageApi.open({
        type: "error",
        content: "Provide a valid email and password",
      });
      return;
    }
    setLoading(true);
    try {
      let res = await axios.post(`${BASE_URI}/login`, {
        email,
        password,
      });
      console.log(res.data);
      setLoading(false);

      if (res.data.success == false) {
        messageApi.open({
          type: "error",
          content: "Wrong login credentials",
        });
        return;
      }
      localStorage.setItem(
        LocalStorageKeys.ADMIN_EMAIL,
        res.data.jsonData.email
      );
      localStorage.setItem(LocalStorageKeys.ADMIN_NAME, res.data.jsonData.name);
      localStorage.setItem(LocalStorageKeys.TOKEN, res.data.token);
      localStorage.setItem(
        LocalStorageKeys.BUSINESS_ID,
        res.data.business[0].id
      );
      localStorage.setItem(
        LocalStorageKeys.BUSINESS_NAME,
        res.data.business[0].business_name
      );
      navigate("/inventory");
    } catch (e) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "Wrong login credentials",
      });
      console.log(e);
    }
  };

  useEffect(() => {
    // CHECK ADMIN AUTHORIZATION
    if (
      loginDetails.BUSINESS_ID != undefined ||
      loginDetails.ADMIN_NAME != undefined ||
      loginDetails.ADMIN_EMAIL != undefined ||
      loginDetails.BUSINESS_NAME != undefined
    ) {
      navigate("/inventory");
    }
  }, []);
  return (
    <>
      <section
        className="sign-up-in-section bg-dark ptb-60"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        {contextHolder}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8 col-12">
              <div className="logo-wrapper  justify-center flex items-center">
                <Link to="/" className="mb-4 d-block text-center">
                  {/* <img
                    src={require("../4.png")}
                    alt="logo"
                    className="img-fluid"
                  /> */}
                  <p className="text-[4rem]">lisa</p>
                </Link>
              </div>

              <div className="register-wrap p-5 bg-light shadow rounded-custom">
                <h1 className="h3">Nice to Seeing You Again</h1>
                <p className="text-muted">
                  Please log in to access your account web-enabled methods of
                  innovative niches.
                </p>

                <div className="action-btns"></div>

                <form action="#" className="mt-4 register-form">
                  <div className="row">
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          id="email"
                          aria-label="email"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="password" className="mb-1">
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          placeholder="Password"
                          id="password"
                          aria-label="Password"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      {!loading ? (
                        <button
                          onClick={login}
                          //   type="submit"
                          className="btn bg-[rgba(39,116,233,1)] text-white mt-3 d-block w-100"
                        >
                          Sign In
                        </button>
                      ) : (
                        <button
                          //   type="submit"
                          disabled
                          className="btn bg-[rgba(39,116,233,0.7)] text-white mt-3 d-block w-100"
                        >
                          Signing in...
                        </button>
                      )}
                    </div>
                  </div>
                  <p className="font-monospace fw-medium text-center text-muted mt-3 pt-4 mb-0">
                    Don’t have an account?
                    <Link to="/register" className="text-decoration-none">
                      Sign up Today
                    </Link>
                    <br />
                    <Link to="/reset/password" className="text-decoration-none">
                      Forgot password
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;
