import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import SideBar from "./components/Sidebar.tsx";
import Menu from "./pages/Menu.tsx";
import OrderPayment from "./pages/OrderPayment.tsx";
import Inventory from "./pages/Inventory.tsx";
import SignIn from "./pages/SignIn.tsx";
import { useEffect, useState } from "react";
import Register from "./pages/Register.tsx";
import Verify from "./pages/Verify.tsx";
import AddBusiness from "./pages/AddBusiness.tsx";
import AddCatalogue from "./pages/AddCatalogue.tsx";
import AddTiming from "./pages/AddTiming.tsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/create-business" element={<AddBusiness />} />
        <Route path="/add-catalogue" element={<AddCatalogue />} />
        <Route path="/add-time" element={<AddTiming />} />

        <Route element={<SideBar />}>
          {/* <Route path="/menu" element={<Menu />} /> */}
          <Route path="/orders" element={<OrderPayment />} />
          <Route path="/inventory" element={<Inventory />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
