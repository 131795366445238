import React, { useEffect, useState } from "react";
// import React, { useState } from 'react';
import { Button, Modal, Table } from "antd";
import axios from "axios";
import { BASE_URI } from "../BASE_URI";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";

export default function OrderPayment() {
  const [active, setActive] = useState(2);
  const [orderslist, serorders] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [customerDetails, setcustomerDetails] = useState();

  const getOrders = async () => {
    let token = localStorage.getItem(LocalStorageKeys.TOKEN);
    let businessID =
      localStorage.getItem(LocalStorageKeys.BUSINESS_ID) ||
      localStorage.getItem(LocalStorageKeys.ADDED_BUSINESS);
    let startDateWeekly = "01-01-2000";
    let endDate = new Date().toLocaleDateString("en-CA");
    // console.log(endDate)

    try {
      let res = await axios.get(
        `${BASE_URI}/rdpw-transactions/${businessID}?start_date=${startDateWeekly}&end_date=${endDate}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      serorders(
        res.data.jsonData.filter((data) => data.payment_method == "pay-later")
      );
    } catch (err) {
      console.log(err);
    }

    // console.log(res.data);
  };

  const [item, setItem] = useState();

  useEffect(() => {
    getOrders();
  }, []);

  const orders = [
    {
      name: "product 1",
      count: 3,
      price: 40,
    },
    {
      name: "product 1",
      count: 3,
      price: 40,
    },
    {
      name: "product 1",
      count: 3,
      price: 40,
    },
    {
      name: "product 1",
      count: 3,
      price: 40,
    },
    {
      name: "product 1",
      count: 3,
      price: 40,
    },
  ];

  const getClient = async (item) => {
    console.log(item);
    try {
      let token = localStorage.getItem(LocalStorageKeys.TOKEN);

      // console.log(endDate)

      let res = await axios.get(`${BASE_URI}/get-client/${item.client_id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      console.log(res.data.jsonData);

      setcustomerDetails(res.data.jsonData);
      setItem(item);
    } catch (e) {
      console.log(e);
    }
  };

  const category = [
    {
      id: 1,
      category_name: "New Orders",
    },
    // { id: 2, category_name: "Completed" },
    // {
    //   id: 3,
    //   category_name: "Unpaid",
    // },
    // {
    //   id: 4,
    //   category_name: "Failed",
    // },
  ];
  const [itemid, setactiveorder] = useState(114);
  return (
    <div className="flex justify-center my-[2rem]">
      <div className="w-2/3 ">
        <input
          placeholder="Search Orders"
          className="outline-none w-full border-2 rounded-md bg-white px-3 py-3"
        />
        <div className="flex gap-x-[1rem] my-6 ">
          {category.map((c) => {
            return (
              <div
                onClick={() => {
                  setActive(c.id);
                }}
                className={`${
                  active == c.id ? "bg-[#2774E9] text-white" : "bg-[#EAEAEA]"
                } px-[1.6rem] py-[0.6rem] rounded-[1.3rem] cursor-pointer`}
              >
                {c.category_name}
              </div>
            );
          })}
        </div>
        {/* modal */}

        <OrderDetail
          isModalOpen={isModalOpen}
          itemid={itemid}
          item={item}
          showModal={showModal}
          clientDetails={customerDetails}
          handleOk={handleOk}
          handleCancel={handleCancel}
          setIsModalOpen={setIsModalOpen}
        />
        {/* end modal */}
        {orderslist.map((item) => {
          let year = new Date().getFullYear();
          let month = new Date().getMonth();
          let date = new Date().getDate();
          let hour = item.profit;
          console.log(year);
          return (
            <div className="bg-[#fff] hover:shadow-lg hover:scale-101 py-4 px-6 transition-all duration-500  my-2 border rounded-lg  ">
              <p>#Order #{item.id}</p>
              <div className="flex justify-between">
                <div className="text-[rgba(0,0,0,0.5)]  py-2 rounded-[5rem] cursor-pointer">
                  {new Date(item.created_at).toDateString()}{" "}
                  {new Date(item.created_at).toLocaleTimeString()}
                </div>
                <div className="flex text-[0.8rem]">
                  Booked Time:{" "}
                  {new Date(year, month, date, hour, 0, 0).toDateString()}{" "}
                  {new Date(year, month, date, hour, 0, 0).toLocaleTimeString()}
                </div>
                <div className=" px-4 py-2 rounded-[5rem] cursor-pointer"></div>
                <div className=" px-4 py-2 rounded-[5rem] text-[rgba(233,120,39,1)] cursor-pointer">
                  KES {item.price}
                </div>
                <div
                  onClick={() => {
                    getClient(item);
                    setIsModalOpen(true);
                  }}
                  className="bg-[rgba(39,116,233,1)] shadow-lg px-7 py-2 rounded-[5rem] cursor-pointer"
                >
                  <p className="text-white">Details</p>
                </div>
              </div>
            </div>
          );
        })}
        {orderslist.length == 0 && (
          <div
            style={{ width: "100%", height: "100%", justifyContent: "center" }}
          >
            No New Order Received
          </div>
        )}
      </div>
    </div>
  );
}

function OrderDetail({
  isModalOpen,
  item,
  showModal,
  itemid,
  handleOk,
  handleCancel,
  setIsModalOpen,
  clientDetails,
}) {
  const orderCollumns = [
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Qty",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Price",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Total Price",
      dataIndex: "address",
      key: "address",
    },
  ];

  const [active, setActive] = useState(1);

  let id_placeholder = "";

  if (item) {
    id_placeholder = item.id;
  }

  const [saving, setSaving] = useState(false);
  let token = localStorage.getItem(LocalStorageKeys.TOKEN);

  const confirmpayment = (active) => {
    setSaving(true);
    axios
      .put(
        `${BASE_URI}/transactions/paymentmethod/${item.id}`,
        {
          payment_method: active == 1 ? "mpesa" : "cash",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setIsModalOpen(false);
        console.log(res.data);
        window.location.reload(false);
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        console.log(err);
      });

    // console.log(payment_method);
  };

  return (
    <>
      <Modal
        closable={false}
        title={`Order #${id_placeholder} Details`}
        width={1200}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="flex justify-between">
          {/* <div className="text-green-500">Paid Created by James</div> */}
          <div className="flex gap-[4rem] ">
            <div className="text-[1.4rem]">Orders Totals</div>
            {item != null ? (
              <div className="text-[1.4rem]">KES {item.price}</div>
            ) : (
              <div className="text-[1.4rem]">KES 0</div>
            )}
          </div>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-[3rem]">
            <div>
              <Table columns={orderCollumns} />
            </div>
            {clientDetails != null ? (
              <div>
                <div className="mt-[2rem] text-[1.5rem]">Customer Details</div>
                <div className="border p-6 rounded-lg">
                  <p className="text-[rgba(0,0,0,0.6)] my-1">Phone Number</p>
                  <p>{clientDetails.phone}</p>
                  <p className="text-[rgba(0,0,0,0.6)] my-1">Location</p>
                  <p>{clientDetails.email}</p>{" "}
                  <p className="text-[rgba(0,0,0,0.6)] my-1">Name (optional)</p>
                  <p>{clientDetails.name}</p>
                </div>
                <p>Payment Method</p>
                <div className="flex justify-between w-3/4 my-[2rem]">
                  <div
                    onClick={() => setActive(1)}
                    className={` py-2 px-7 border-black border rounded-[3rem] cursor-pointer ${
                      active == 1
                        ? "bg-[rgba(39,116,233,1)] text-white border-none"
                        : "bg-#eaeaea"
                    }`}
                  >
                    M-pesa
                  </div>
                  <div
                    onClick={() => setActive(2)}
                    className={` py-2 px-7 border-black border rounded-[3rem] cursor-pointer ${
                      active == 2
                        ? "bg-[rgba(39,116,233,1)] text-white border-none"
                        : "bg-#eaeaea"
                    }`}
                  >
                    Cash
                  </div>
                </div>
                {!saving ? (
                  <div
                    onClick={() => {
                      confirmpayment(active);
                    }}
                    className={` py-2 px-7 border-black border w-1/2 justify-center flex rounded-[3rem] cursor-pointer ${"bg-[rgba(39,116,233,1)] text-white border-none"}`}
                  >
                    Confirm Payment
                  </div>
                ) : (
                  <div
                    className={` py-2 px-7 border-black border w-1/2 justify-center flex rounded-[3rem] cursor-pointer ${"bg-[rgba(39,116,233,0.4)] text-white border-none"}`}
                  >
                    Confirming Payment...
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col justify-between w-3/4 my-[2rem]">
                No Customer Details
                <div className="flex justify-between ">
                  <div
                    onClick={() => setActive(1)}
                    className={` py-2 px-7 border-black border rounded-[3rem] cursor-pointer ${
                      active == 1
                        ? "bg-[rgba(39,116,233,1)] text-white border-none"
                        : "bg-#eaeaea"
                    }`}
                  >
                    M-pesa
                  </div>
                  <div
                    onClick={() => setActive(2)}
                    className={` py-2 px-7 border-black border rounded-[3rem] cursor-pointer ${
                      active == 2
                        ? "bg-[rgba(39,116,233,1)] text-white border-none"
                        : "bg-#eaeaea"
                    }`}
                  >
                    Cash
                  </div>
                  {/* <div
                    onClick={() => setActive(3)}
                    className={` py-2 px-7 border-black border rounded-[3rem] cursor-pointer ${
                      active == 3
                        ? "bg-[rgba(39,116,233,1)] text-white border-none"
                        : "bg-#eaeaea"
                    }`}
                  >
                    Pay Later
                  </div> */}
                </div>
                <div
                  onClick={() => {}}
                  className={` py-2 px-7 border-black border w-1/2 justify-center flex rounded-[3rem] cursor-pointer ${
                    active == 4
                      ? "bg-[rgba(39,116,233,1)] text-white border-none"
                      : "bg-#eaeaea"
                  }`}
                >
                  Confirm Payment
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
